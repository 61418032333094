<template>
  
    <!-- add brand modal starts here -->
    <transition name="modal">
        <div class="modalBg categoryResponsiveModal modal-active" v-if="brandModal.isOpen">
            <div class="modalLayers" @click.prevent="brandModal.isOpen = false"></div>
            <div class="modalBody">

                <div class="addProductTitle">
                    <h3>Brand Details</h3>
                </div>

                <div class="oderSlideBox productSideBox" style="position: relative; top: 15px; right: 0; width: 100%">
                    <div id="London" class="tabcontent">
                        <div class="contaient-div horizontal-scroll-fix contaient-div-b">
                            <div class="editCategoryBlock createCategoryPopup">
                                <ValidationObserver ref="brandFormObserver">
                                    <form @submit.prevent="validate()">
                                        <div class="row">

                                            <!-- name field starts here -->
                                            <div class="col-md-6">
                                                <div class="formContentBox" style="position: relative">
                                                    <label for="brand_name_field">Name <span>*</span></label>
                                                    <ValidationProvider name="name" rules="required|max:250" v-slot="{ errors }">
                                                        <input
                                                            type="text"
                                                            name="brand_name_field"
                                                            id="brand_name_field"
                                                            placeholder="Name"
                                                            v-model="brandModal.name"
                                                            @input="slugGeneration('name')"
                                                        />
                                                        <p class="remainingTxt" style="top: 0px" v-if="brandModal.name">
                                                            <span>{{ brandModal.name ? brandModal.name.length <= 50 ? 50 - brandModal.name.length : 0 : 50 }}</span>
                                                            Remaining
                                                        </p>
                                                        <p class="userInfoTxt" v-if=" brandModal.name && brandModal.name.length <= 50">
                                                            Do not exceed 50 characters when entering the brand name for SEO
                                                        </p>
                                                        <p class="userInfoTxt" v-else-if=" brandModal.name && brandModal.name.length > 50 ">
                                                            Not SEO friendly
                                                        </p>
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </ValidationProvider>
                                                </div>
                                            </div>
                                            <!-- name field ends here -->

                                            <!-- slug field starts here -->
                                            <div class="col-md-6">
                                                <div class="formContentBox" style="position: relative">
                                                    <label for="brand_slug_field">Slug <span>*</span></label>
                                                    <ValidationProvider name="slug" rules="required|max:250" v-slot="{ errors }">
                                                        <input 
                                                            name="brand_slug_field" 
                                                            id="brand_slug_field"
                                                            placeholder="Slug"
                                                            v-model="brandModal.slug" 
                                                            @blur="slugGeneration('slug')"
                                                        />
                                                        <p class="remainingTxt" style="top: 0px" v-if="brandModal.slug">
                                                            <span>{{ brandModal.slug ? brandModal.slug.length <= 60 ? 60 - brandModal.slug.length : 0 : 60}}</span>
                                                            Remaining
                                                        </p>
                                                        <p class="userInfoTxt" v-if="brandModal.slug && brandModal.slug.length <= 60">
                                                            Do not exceed 60 characters when entering the brand slug for SEO
                                                        </p>
                                                        <p class="userInfoTxt" v-else-if="brandModal.slug && brandModal.slug.length > 60">
                                                            Not SEO friendly
                                                        </p>
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </ValidationProvider>
                                                </div>
                                            </div>
                                            <!-- slug field ends here -->

                                            <!-- description field starts here -->
                                            <div class="col-md-12">
                                                <div class="formContentBox" style="position: relative">
                                                    <ValidationProvider name="description" rules="required|max:3000" v-slot="{ errors }">
                                                        <label for="brand_description_field">Description <span>*</span></label>
                                                        <textarea
                                                            class="form-control foam-border comment-box mt-0"
                                                            placeholder="Description"
                                                            rows="3"
                                                            cols="30"
                                                            name="brand_description_field"
                                                            v-model="brandModal.description"
                                                        >
                                                        </textarea>
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </ValidationProvider>
                                                </div>
                                            </div>
                                            <!-- description field ends here -->

                                            <!-- image field starts here -->
                                            <div class="col-md-12" id="brand-image">
                                                <div class="product-description">
                                                    <span class="product-discription-label" style="margin-bottom: 10px">
                                                        Image<span> *</span>
                                                    </span>
                                                </div>
                                                <input
                                                    type="file"
                                                    ref="brandImageInput" 
                                                    @change.prevent="addImageHandle"
                                                    id="feature_img_brand"
                                                    accept="image/png,image/jpg,image/jpeg,image/webp"
                                                    hidden
                                                />
                                                <label for="feature_img_brand" class="imgLabel" style="display: block">
                                                    <div class="category-tab-img">
                                                        <svg v-show="brandModal.image_src == ''" id="Layer_1" style="enable-background: new 0 0 512 512" version="1.1" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                                            <g>
                                                            <path d="M384,265H264v119h-17V265H128v-17h119V128h17v120h120V265z"/>
                                                            </g>
                                                        </svg>
                                                        <img v-show="brandModal.image_src" :src="brandModal.image_src" alt=""/>
                                                    </div>
                                                    <div class="optionsLayer" :class="{ 'visible': brandModal.image }">
                                                        <button type="button" @click.prevent="attachmentOperations('preview')">
                                                            <i class="el-icon-zoom-in"></i>
                                                        </button>
                                                        <button type="button" @click.prevent="attachmentOperations('remove')">
                                                            <i class="el-icon-delete"></i>
                                                        </button>
                                                    </div>
                                                    <span class="text-danger" v-if="imageValidations.imgIsRequired">Image field is required</span>
                                                </label>
                                            </div>
                                            <!-- image field ends here -->

                                            <div class="col-md-12">
                                                <div class="sideTableTitle addNewProductTitle">
                                                    <h2>SEO</h2>
                                                </div>
                                            </div>

                                            <!-- meta title field starts here -->
                                            <div class="col-md-12">
                                                <div class="formContentBox" style="position: relative">
                                                    <ValidationProvider name="meta title" rules="max:250" v-slot="{ errors }">
                                                        <label for="brand_meta_title_field">Meta Title</label>
                                                        <input
                                                            type="text"
                                                            name="brand_meta_title_field"
                                                            id="brand_meta_title_field"
                                                            placeholder="Meta title"
                                                            v-model="brandModal.meta_title"
                                                        />
                                                        <p class="remainingTxt" style="top: 0px" v-if="brandModal.meta_title">
                                                            <span>{{ brandModal.meta_title ? brandModal.meta_title.length <= 60 ? 60 - brandModal.meta_title.length : 0 : 60 }}</span>
                                                            Remaining
                                                        </p>
                                                        <p class="userInfoTxt" v-if=" brandModal.meta_title && brandModal.meta_title.length <= 60">
                                                            Do not exceed 60 characters when entering the meta title for SEO
                                                        </p>
                                                        <p class="userInfoTxt" v-else-if="brandModal.meta_title && brandModal.meta_title.length > 60 ">
                                                            Not SEO friendly
                                                        </p>
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </ValidationProvider>
                                                </div>
                                            </div>
                                            <!-- meta title field ends here -->
                                                
                                            <!-- meta description field starts here -->
                                            <div class="col-md-12">
                                                <div class="formContentBox" style="position: relative">
                                                    <ValidationProvider name="meta description" :rules="{max:500}" v-slot="{ errors }">
                                                        <label for="brand_meta_description_field">Meta Description</label>
                                                        <textarea
                                                            class="form-control foam-border comment-box mt-0"
                                                            rows="3"
                                                            id="brand_meta_description_field"
                                                            cols="30"
                                                            name="brand_meta_description_field"
                                                            placeholder="Meta description"
                                                            v-model="brandModal.meta_description"
                                                        >
                                                        </textarea>
                                                        <p class="remainingTxt" style="top: 0px" v-if="brandModal.meta_description">
                                                            <span>{{ brandModal.meta_description ? brandModal.meta_description.length <= 300 ? 300 - brandModal.meta_description.length : 0 : 300 }}</span>
                                                            Remaining
                                                        </p>
                                                        <p class="userInfoTxt" v-if=" brandModal.meta_description && brandModal.meta_description.length <= 300 ">
                                                            Do not exceed 300 characters when entering the brand name for SEO
                                                        </p>
                                                        <p class="userInfoTxt" v-else-if=" brandModal.meta_description && brandModal.meta_description.length > 300 ">
                                                            Not SEO friendly
                                                        </p>
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </ValidationProvider>
                                                </div>
                                            </div>
                                            <!-- meta description field ends here -->

                                            <!-- active & indexed field starts here -->
                                            <div class="col-md-12">
                                                <div class="updateProductBtnBox categorySideBar">
                                                    <div class="updateCtmCheckBox">
                                                        <p>Status</p>
                                                        <div class="productStatusBox">
                                                            <p>DISABLE</p>
                                                            <div class="actionSwitch">
                                                                <label class="switch">
                                                                <input
                                                                    type="checkbox"
                                                                    id="brand_status_switch"
                                                                    v-model="brandModal.isActive"
                                                                />
                                                                <span class="slider round"></span>
                                                                </label>
                                                            </div>
                                                            <p>ACTIVE</p>
                                                        </div>
                                                    </div>
                                                    <div class="updateCtmCheckBox">
                                                        <p>Indexed</p>
                                                        <div class="productStatusBox">
                                                            <p>NO</p>
                                                            <div class="actionSwitch">
                                                                <label class="switch">
                                                                    <input
                                                                        type="checkbox"
                                                                        id="brand_indexed_switch"
                                                                        :value="brandModal.isIndexed"
                                                                        v-model="brandModal.isIndexed"
                                                                    />
                                                                    <span class="slider round"></span>
                                                                </label>
                                                            </div>
                                                            <p>YES</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- active & indexed field ends here -->

                                            <!-- saving button starts here -->
                                            <div class="col-md-12 mt-3">
                                                <div class="assign-btn">
                                                    <loaderBtn class="product-btn" v-if="brandModal.isLoading"/>
                                                        <button v-else type="submit" class="product-btn">
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                            <!-- saving button ends here -->

                                        </div>
                                    </form>
                                </ValidationObserver>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modalCloseIcon">
                    <button @click.prevent="closeBrandForm">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                            <path 
                                id="cross-small"
                                d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" 
                                transform="translate(-5.483 -5.483)" 
                                fill="#fd4d5d" 
                                stroke="#fd4d5d" 
                                stroke-width="0.3" 
                            />
                        </svg>
                    </button>
                </div>

            </div>
        </div>

    </transition>
    <!-- add brand modal ends here -->

</template>

<script>
import loaderBtn from "../loaderBtn.vue";
import {MessageBox, Message, Loading} from 'element-ui'

export default {
    name:"AddBrandModal",
    data(){
        return {

            brandModal:{
                name: '',
                slug: '',
                description: '',
                image: null,
                image_src: '',
                meta_title: '',
                meta_description: '',
                isActive: true,
                isIndexed: true,
                isOpen: false,
                isLoading: false,
                form: '',
            },

            imageValidations: {
                imgIsRequired: false,
                imgInvalidFormat: false,
                isNotImg: false,
            },


        }
    },
    props:{
    },
    components:{
        loaderBtn,
    },
    computed:{
    },
    watch:{
    },
    methods:{

        openModal(form){

            this.brandModal = {
                name: '',
                slug: '',
                description: '',
                image: null,
                image_src: '',
                meta_title: '',
                meta_description: '',
                isActive: true,
                isIndexed: true,
                isOpen: true,
                isLoading: false,
                form: form,
            };

            this.$nextTick((_) => {

                this.resetImageValidations();

                if(this.$refs.brandFormObserver){

                    this.$refs.brandFormObserver.reset();

                }

            });

        },

        slugGeneration(field){

            let slug = '';

            if(field == 'name'){

                slug = this.brandModal.name.toLowerCase().trim();
                slug = slug.replace(/[^a-zA-Z0-9 ]/g, '');
                slug = slug.replace(/\s/g, '-');
                slug = slug.replace(/(\W)\W*/g, '$1');
                slug = slug.replace(/-$/, ""); 

            }else if(field == 'slug'){

                slug = this.brandModal.slug.toLowerCase().trim();
                slug = slug.replace(/[^a-zA-Z0-9- ]/g, '');
                slug = slug.replace(/\s/g, '-');
                slug = slug.replace(/(\W)\W*/g, '$1');

            }

            this.brandModal.slug = slug;

        },

        addImageHandle(e){

            if(e.target.files.length > 0){

                this.imageValidations = {
                    imgIsRequired:false,
                    imgInvalidFormat:false,
                    isNotImg:false,
                }

                const file = e.target.files[0];

                if(file.name.length > 50){

                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: "Uploaded file name must not exceed 50 characters.",
                    }); 

                    this.imageValidations.imgIsRequired = false

                    if(this.$refs.brandImageInput){

                        this.$refs.brandImageInput.value = '';

                    }

                    return

                }

                if(file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/webp' || file.type == 'image/png'){
                    
                    this.brandModal.image = file;
                    this.brandModal.image_src = URL.createObjectURL(file);

                    this.imageValidations.imgIsRequired = false;

                }else if(file.type.includes('image/')){

                    let uploadedFileType = file.type.slice(6);

                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: `${uploadedFileType.toUpperCase()} is not a valid file type.`,
                    });

                }else{

                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: "Uploaded file must be an image.",
                    }); 

                }

                if(this.$refs.brandImageInput){

                    this.$refs.brandImageInput.value = '';

                }

            }

        },
        resetImageValidations(){

            this.imageValidations = {
                imgIsRequired:false,
                imgInvalidFormat:false,
                isNotImg:false,
            }

        },
        attachmentOperations(operation){

            if(operation == 'preview'){

                this.$emit('image_preview',this.brandModal.image_src);

            }else if(operation == 'remove'){

                MessageBox.confirm(
                    'Are you sure you want to remove the image?',
                    'Warning',
                    {
                        type: 'warning',
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'Cancel',
                    }
                ).then(() => {

                    this.brandModal.image = null;
                    this.brandModal.image_src = "";

                    this.$notify({
                        type: "success",
                        title: "Success",
                        message: "Image has been successfully removed.",
                    });

                }).catch(() => {})

            }

        },

        validate(){

            this.$refs.brandFormObserver.validate().then((success) => {

                if(!success){

                    const errors = Object.entries(this.$refs.brandFormObserver.errors)
                    .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

                    this.$refs.brandFormObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center" });

                    if(errors.length > 0){

                        if(!this.brandModal.image){

                            this.imageValidations.imgIsRequired = true;

                        }

                    }else{

                        if(!this.brandModal.image){

                            this.imageValidations.imgIsRequired = true;

                            document.querySelector("#brand-image").scrollIntoView({ behavior: 'smooth',block: "center" });

                        }

                    }

                }else{

                    if(!this.brandModal.image){

                        this.imageValidations.imgIsRequired = true;

                        document.querySelector("#brand-image").scrollIntoView({ behavior: 'smooth',block: "center" });

                    }else{

                        this.handleAddBrand();

                    }

                }

            });

        },

        async handleAddBrand(){

            let formData = new FormData();

            formData.append('name', this.brandModal.name.trim());
            formData.append('slug', this.brandModal.slug.replace(/-$/, ""));
            formData.append('description', this.brandModal.description);
            formData.append('isActive', this.brandModal.isActive ? 1 : 0);
            formData.append('isIndexed', this.brandModal.isIndexed ? 1 : 0);

            if(this.brandModal.meta_title){

                formData.append('meta_title', this.brandModal.meta_title);

            }

            if(this.brandModal.meta_description){

                formData.append('meta_description', this.brandModal.meta_description);

            }

            if(this.brandModal.image){

                formData.append('image', this.brandModal.image);

            }

            this.brandModal.isLoading = true;
            try{
                let res = await this.$axios.post('/brand/add', formData);
                if(res.data.status_code == '1391'){

                    this.$notify({
                        type: "success",
                        title: "Success",
                        message: res.data.message,
                    });

                    this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                    this.$store.commit('gallery_module/CLEAR_MODULE');

                    let record = {
                        brand : res.data.brand,
                        form: this.brandModal.form
                    }

                    this.$emit('brand_add_success',record);

                    this.brandModal.isOpen = false;

                }
            }catch(error){

                if(error.response){

                    if(error.response.data.error.name){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.name[0],
                        });

                    }else if(error.response.data.error.slug){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.slug[0],
                        });

                    }else if(error.response.data.error.image){
                                
                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: "We encountered difficulties processing the featured image.",
                        });

                    }else if(error.response.data.error.includes('storage limit has been reached')){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error,
                        });

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }
                }else{

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    });

                }

            }finally{

                this.brandModal.isLoading = false;

            }

        }

    },
}
</script>

<style scoped>

    .category-tab-img {
        /* height: 400px;
        width: 400px; */
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        overflow: hidden;
        position: relative;
    }
    .category-tab-img:hover {
        border-color: #409EFF;
    }
    .category-tab-img img{
        height: 100%;
        width: 100%;
    }
    .category-tab-img svg{
        position: absolute;
        width: 50px;
        top: 43%;
        left: 0;
        right: 0;
        margin: 0 auto;
        fill: #d9d9d9;
    }
    .category-tab-img:hover svg{
        fill: #409EFF;
    }


    /* Transition Effects */
    .modal-enter-active, 
    .modal-leave-active {
        transition: all 0.3s ease-in-out;
    }

    /* Initial state when entering */
    .modal-enter{
        opacity: 0;
        visibility: hidden;
    }

    /* Final state when leaving */
    .modal-leave-to {
        opacity: 0;
        visibility: hidden;
    }

</style>