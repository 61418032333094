<template>

    <!-- reviews popup starts here -->
    <transition name="modal">
        <div class="modalBg modal-active" v-if="reviewsModal.isOpen">
            <div class="modalLayers" @click.prevent="reviewsModal.isOpen = false"></div>
            <div class="modalBody">

                <div class="addProductTitle">
                    <h3>{{ reviewsModal.item.name }}'s Reviews</h3>
                </div>

                <div class="searchProductForm" style="margin-bottom:0px !important">
                    <input
                        type="search"
                        name="searchReview"
                        id="searchReview"
                        placeholder="Search Review"
                        v-model="reviewsModal.searchedKeyword"
                        @input="loadItemReviews('search', 1)"
                        style="width: 100%;"
                    />
                </div>

                <div class="oderSlideBox productSideBox" style="position: relative; top: 15px; right: 0; width: 100%; min-height: 400px; max-height: 400px; overflow: auto;">
                    <div id="London" class="tabcontent">
                        <div class="content-table">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Customer Image">Image</p>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Customer Name">NAME</p>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Customer Email">EMAIL</p>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Comment">COMMENT</p>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Rating">RATING</p>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Active/Disable">ACTIVE/DISABLE</p>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-if="reviewsModal.isLoading">
                                    <tr v-for="i in 5" :key="i">

                                        <td>
                                            <div class="ctmCheckWrap">
                                                <div class="editFieldTitle customerNameField">
                                                    <vue-skeleton-loader
                                                        type="circle"
                                                        :width="36"
                                                        :height="36"
                                                        animation="fade"
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <div class="ctmTableImgWrap">
                                                    <vue-skeleton-loader
                                                        type="rect"
                                                        :width="100"
                                                        :height="20"
                                                        animation="fade"
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <div class="ctmTableImgWrap">
                                                    <vue-skeleton-loader
                                                        type="rect"
                                                        :width="100"
                                                        :height="20"
                                                        animation="fade"
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <div class="ctmTableImgWrap">
                                                    <vue-skeleton-loader
                                                        type="rect"
                                                        :width="150"
                                                        :height="20"
                                                        animation="fade"
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <div class="ctmTableImgWrap">
                                                    <vue-skeleton-loader
                                                        type="rect"
                                                        :width="100"
                                                        :height="20"
                                                        animation="fade"
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <div class="ctmTableImgWrap">
                                                    <vue-skeleton-loader
                                                        type="rect"
                                                        :width="100"
                                                        :height="20"
                                                        animation="fade"
                                                    />
                                                </div>
                                            </div>
                                        </td>

                                    </tr>
                                </tbody>
                                <tbody v-else>
    
                                    <tr v-if="reviewsModal.reviews.length == 0">
                                        <td colspan="7" style="text-align:center" v-if="reviewsModal.searchedKeyword">
                                            <p class="mb-0">
                                                No reviews found matching '{{reviewsModal.searchedKeyword}}'
                                            </p>
                                        </td>
                                        <td colspan="7" style="text-align:center" v-else><p class="mb-0">No Reviews Available</p></td>
                                    </tr>

                                    <tr v-for="(review,index) in reviewsModal.reviews" :key="`${review.id}-${index}`">
                                    
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <div style="width: 100%;">
                                                    <div class="ctmCheckWrap">
                                                        <div class="ctmDataTableImgBox">
                                                            <img 
                                                                :src="review.user.image ? base_image_url + settings.store_id + '/user/' + review.user.image : '/images/customer_noImage.png'" 
                                                                @error="handleImageError"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <p>{{  review.user.first_name + " " + review.user.last_name }}</p>
                                            </div>
                                        </td>

                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <p>{{ review.user.email }}</p>
                                            </div>
                                        </td>

                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <p>{{ review.comment }}</p>
                                            </div>
                                        </td>

                                        <td>
                                            <el-rate
                                                v-model="review.rating"
                                                disabled
                                                :colors="ratingColors"
                                                show-score
                                                text-color="#ff9900"
                                                score-template="{value}"
                                            />
                                        </td>

                                        <td>
                                            <div class="actionSwitch">
                                                <label class="switch">
                                                    <input
                                                        type="checkbox"
                                                        v-model="review.isActive"
                                                        :value="review.isActive"
                                                        @change="updateReviewStatus(review)"
                                                    />
                                                    <span class="slider"></span>
                                                </label>
                                            </div>
                                        </td>

                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <hr>

                <el-pagination
                    background
                    layout="prev, pager, next"
                    :page-count="reviewsPagination.total"
                    @current-change="reviewsPaginateChangeHandle"
                    class="mt-4"
                />

                <hr>

                <div class="modalCloseIcon">
                    <button @click.prevent="reviewsModal.isOpen = false">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                            <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                        </svg>
                    </button>
                </div>

            </div>
        </div>
    </transition>
    <!-- reviews popup ends here -->

</template>

<script>
import { MessageBox, Message, Loading } from 'element-ui'
import { mapGetters } from 'vuex';

export default {
    name:"ReviewsModal",
    data(){
        return {
            base_image_url: process.env.VUE_APP_STORE_IMAGE_BASE_URL,


            reviewsModal:{
                item: {
                    id: '',
                    name: '',
                },
                reviews: [],
                searchedKeyword: '',
                isOpen: false,
                isLoading: false,
            },
            reviewsPagination:{
                current_page: 0,
                total: 0,
                previous_page: 0,
                last_page: 0,
            },
            reviewsTimer: undefined,
            ratingColors: ['#FD4D5D', '#E0E847', '#1EC2C2'],

        }
    },
    props:{
        moduleType:{
            type: String,
            required: true,
            description: 'module type'
        },
    },
    components:{
    },
    computed:{
        ...mapGetters({
            settings: 'settings_module/settings',
        }),
    },
    watch:{
    },
    methods:{

        openReviewsModal(item){

            this.reviewsModal = {
                item:{
                    id: item.id,
                    name: item.name,
                },
                reviews: [],
                searchedKeyword: '',
                isOpen: true,
                isLoading: false,
            }

            this.reviewsPagination = {
                current_page: 0,
                total: 0,
                previous_page: 0,
                last_page: 0,
            }

            this.loadItemReviews('simple', 1);

        },
        reviewsPaginateChangeHandle(currentPage, pageSize){

            this.loadItemReviews('simple', currentPage);

        },
        loadItemReviews(operation, page){

            if(operation == 'simple'){

                this.loadReviews(page, this.reviewsModal.searchedKeyword);

            }else if(operation == 'search'){

                this.reviewsModal.isLoading = true;

                clearTimeout(this.reviewsTimer);

                this.reviewsTimer = setTimeout(async () => {

                    this.loadReviews(page, this.reviewsModal.searchedKeyword);

                }, 300);

            }

        },
        async loadReviews(page, keyword){

            if(this.moduleType == "product"){

                this.reviewsModal.isLoading = true;
                try{
                    let res = await this.$axios.get('/product/review/all',{
                        params:{
                            product_id: this.reviewsModal.item.id,
                            offset: 10,
                            page: page,
                            search: keyword.trim() ? keyword.trim() : null
                        }
                    });
                    if(res.data.status_code == "1090"){

                        this.reviewsModal.reviews = res.data.reviews.data;

                        this.reviewsPagination = {
                            current_page: page,
                            total: res.data.reviews.last_page,
                            previous_page: 0,
                            last_page: res.data.reviews.last_page
                        }

                    }
                }catch(error){

                    this.reviewsModal.isOpen = false;

                    if(error.response){

                    if(error.response.data.error.includes("deleted or isn't available.")){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error,
                        });

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }

                    }else{

                        this.$message({
                            type: "error",
                            showClose: true,
                            message: error.message,
                        });

                    }

                }finally{

                    this.reviewsModal.isLoading = false;

                }

            }else if(this.moduleType == "product_bundle"){

                this.reviewsModal.isLoading = true;
                try{
                    let res = await this.$axios.get('/product-bundle/review/all',{
                        params:{
                            bundle_id: this.reviewsModal.item.id,
                            offset: 10,
                            page: page,
                            search: keyword.trim() ? keyword.trim() : null
                        }
                    });
                    if(res.data.status_code == "1090"){

                        this.reviewsModal.reviews = res.data.reviews.data;

                        this.reviewsPagination = {
                            current_page: page,
                            total: res.data.reviews.last_page,
                            previous_page: 0,
                            last_page: res.data.reviews.last_page
                        }

                    }
                }catch(error){

                    this.reviewsModal.isOpen = false;

                    if(error.response){

                        if(error.response.data.error.includes("deleted or isn't available.")){

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.error,
                            });

                        }else{

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.message,
                            });

                        }

                    }else{

                        this.$message({
                            type: "error",
                            showClose: true,
                            message: error.message,
                        });

                    }

                }finally{

                    this.reviewsModal.isLoading = false;
                    
                }

            }

        },

        async updateReviewStatus(review){

            let formData = new FormData();

            formData.append('review_id',review.id);
            formData.append('status',review.isActive ? 1 : 0);

            let loader = Loading.service({
                text: "The review is undergoing status update. Please wait!",
                fullscreen: true,
            });

            try{
                let res = await this.$axios.post('/review/status-change',formData);
                if(res.data.status_code == "10094"){

                    this.$notify({
                        type: "success",
                        title: "Success",
                        message: res.data.message,
                    });

                }
            }catch(error){

                if(error.response){

                    if(error.response.data.error.includes("deleted or isn't available")){

                        this.$message({
                            type: "error",
                            showClose: true,
                            message: error.response.data.error,
                        });

                    }else{

                        this.$message({
                            type: "error",
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }

                }else{

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    });

                }

            }finally{

                loader.close();

            }

        },

        //image loading error handling
        handleImageError(e){

            e.target.src = '/images/image_not_found.png'

        },

    },
}
</script>

<style scoped>

    /* Transition Effects */
    .modal-enter-active, 
    .modal-leave-active {
        transition: all 0.3s ease-in-out;
    }

    /* Initial state when entering */
    .modal-enter{
        opacity: 0;
        visibility: hidden;
    }

    /* Final state when leaving */
    .modal-leave-to {
        opacity: 0;
        visibility: hidden;
    }

</style>