<template>

    <!-- add category modal starts here -->
    <transition name="modal">
        <div class="modalBg categoryResponsiveModal modal-active" v-if="categoryModal.isOpen">
            <div class="modalLayers" @click.prevent="categoryModal.isOpen = false"></div>
            <div class="modalBody">
            
                <div class="addProductTitle">
                    <h3>Category Details</h3>
                </div>

                <div class="oderSlideBox productSideBox" style="position: relative; top: 15px; right: 0; width: 100%">
                    <div id="London" class="tabcontent">
                        <div class="contaient-div horizontal-scroll-fix contaient-div-b">
                            <div class="editCategoryBlock createCategoryPopup">
                                <ValidationObserver ref="categoryFormObserver">
                                    <form class="categoryFormBlock" @submit.prevent="validate()">
                                        <div class="row">

                                            <!-- name field starts here -->
                                            <div class="col-md-6">
                                                <div class="formContentBox" style="position: relative">
                                                    <label for="category_name_field">Name <span>*</span></label>
                                                    <ValidationProvider name="name" rules="required" v-slot="{ errors }">
                                                        <input
                                                            type="text"
                                                            name="category_name_field"
                                                            id="category_name_field"
                                                            placeholder="Name"
                                                            v-model="categoryModal.name"
                                                            @input="slugGeneration('name')"
                                                        />
                                                        <p class="remainingTxt" style="top: 0px" v-if="categoryModal.name">
                                                            <span>{{ categoryModal.name ? categoryModal.name.length <= 50 ? 50 - categoryModal.name.length : 0 : 50 }}</span>
                                                            Remaining
                                                        </p>
                                                        <p class="userInfoTxt" v-if=" categoryModal.name && categoryModal.name.length <= 50 ">
                                                            Do not exceed 50 characters when entering the
                                                            category name for SEO
                                                        </p>
                                                        <p class="userInfoTxt" v-else-if=" categoryModal.name && categoryModal.name.length > 50 ">
                                                            Not SEO friendly
                                                        </p>
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </ValidationProvider>
                                                </div>
                                            </div>
                                            <!-- name field ends here -->

                                            <!-- slug field starts here -->
                                            <div class="col-md-6">
                                                <div class="formContentBox" style="position: relative">
                                                    <label for="category_slug_field">Slug <span>*</span></label>
                                                    <ValidationProvider name="slug" rules="required" v-slot="{ errors }">
                                                        <input
                                                            name="category_slug_field"
                                                            id="category_slug_field"
                                                            placeholder="Slug"
                                                            v-model="categoryModal.slug"
                                                            @blur="slugGeneration('slug')"
                                                        />
                                                        <p class="remainingTxt" style="top: 0px" v-if="categoryModal.slug">
                                                            <span>{{ categoryModal.slug ? categoryModal.slug.length <= 60 ? 60 - categoryModal.slug.length : 0 : 60 }}</span>
                                                            Remaining
                                                        </p>
                                                        <p class="userInfoTxt" v-if=" categoryModal.slug && categoryModal.slug.length <= 60">
                                                            Do not exceed 60 characters when entering the
                                                            category slug for SEO
                                                        </p>
                                                        <p class="userInfoTxt" v-else-if=" categoryModal.slug && categoryModal.slug.length > 60">
                                                            Not SEO friendly
                                                        </p>
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </ValidationProvider>
                                                </div>
                                            </div>
                                            <!-- slug field ends here -->

                                            <!-- type field starts here -->
                                            <div class="col-md-6">
                                                <div class="formContentBox">
                                                    <label for="category_type_field">Type <span>*</span></label>
                                                    <select 
                                                        class="active1"
                                                        v-model="categoryModal.category_type"
                                                        name="category_type_field" 
                                                        id="category_type_field"
                                                        @change="typeChangeHandle"
                                                    >
                                                        <option value="main">Main</option>
                                                        <option value="sub">Sub</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <!-- type field ends here -->

                                            <!-- parent field starts here -->
                                            <div class="col-md-6" v-show="categoryModal.category_type == 'sub'">
                                                <div class="formContentBox" style="margin-bottom: 0px;">
                                                    <label for="parent_category">Parent Category <span>*</span></label>
                                                </div>
                                                <div class="vueSelectBlock" style="margin-bottom: 20px;">
                                                    <ValidationProvider name="parent category" :rules="categoryModal.category_type == 'sub' ? 'required' : ''" v-slot="{ errors }">
                                                        <Multiselect
                                                            ref="categoryMultiSelect"
                                                            id="parent_category"
                                                            v-model="categoryModal.parent"
                                                            :options="parentCategories"
                                                            placeholder="Search and select a parent category"
                                                            :custom-label="customProductCategoryName"
                                                            label="name"
                                                            track-by="id"
                                                            :allow-empty="false"
                                                            deselect-label="Can't remove the selected item"
                                                            :searchable="true"
                                                            @search-change="loadProductCategories"
                                                            :loading="categoriesLoader"
                                                        >
                                                            <span slot="noResult">
                                                                No category found matching the searched keyword.
                                                            </span>
                                                        </Multiselect>
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </ValidationProvider>
                                                </div>
                                            </div>
                                            <!-- parent field ends here -->

                                            <!-- description field starts here -->
                                            <div class="col-md-12 mb-3">
                                                <div class="product-description">
                                                    <span class="product-discription-label" style="margin-bottom: 10px">
                                                        Description<span> *</span>
                                                    </span>
                                                </div>
                                                <ValidationProvider name="description" rules="required" v-slot="{ errors }">
                                                    <div class="form-group mb-2 custom-form create-section">
                                                        <vue-editor 
                                                            id="product-description" 
                                                            name="product-description" 
                                                            :editorOptions="categoryEditorOptions"
                                                            v-model="categoryModal.description"
                                                        ></vue-editor>
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                            <!-- description field ends here -->

                                            <!-- image field starts here -->
                                            <div class="col-md-12" id="category-featured-image">
                                                <div class="product-description">
                                                    <span class="product-discription-label" style="margin-bottom: 10px">
                                                        Featured Image<span> *</span>
                                                    </span>
                                                </div>
                                                <input
                                                    type="file"
                                                    ref="categoryImageInput" 
                                                    @change.prevent="addImageHandle"
                                                    id="feature_img"
                                                    accept="image/png,image/jpg,image/jpeg,image/webp"
                                                    hidden
                                                />
                                                <label for="feature_img" class="imgLabel" style="display: block">
                                                    <div class="category-tab-img">
                                                        <svg v-show="categoryModal.image_src == ''" id="Layer_1" style="enable-background: new 0 0 512 512" version="1.1" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                                            <g>
                                                                <path d="M384,265H264v119h-17V265H128v-17h119V128h17v120h120V265z"/>
                                                            </g>
                                                        </svg>
                                                        <img v-show="categoryModal.image_src" :src="categoryModal.image_src" alt=""/>
                                                    </div>
                                                    <div class="optionsLayer" :class="{ 'visible': categoryModal.image }">
                                                        <button type="button" @click.prevent="attachmentOperations('preview')">
                                                            <i class="el-icon-zoom-in"></i>
                                                        </button>
                                                        <button type="button" @click.prevent="attachmentOperations('remove')">
                                                            <i class="el-icon-delete"></i>
                                                        </button>
                                                    </div>
                                                    <span class="text-danger" v-if="imageValidations.imgIsRequired">Image field is required</span>
                                                </label>
                                            </div>
                                            <!-- image field ends here -->

                                            <div class="col-md-12">
                                                <div class="sideTableTitle addNewProductTitle">
                                                    <h2>SEO</h2>
                                                </div>
                                            </div>

                                            <!-- meta title field starts here -->
                                            <div class="col-md-12">
                                                <div class="formContentBox" style="position: relative">
                                                    <label for="meta_title_field">Meta Title</label>
                                                    <input
                                                        type="text"
                                                        name="meta_title_field"
                                                        id="meta_title_field"
                                                        placeholder="Meta title"
                                                        v-model="categoryModal.meta_title"
                                                    />
                                                    <p class="remainingTxt" style="top: 0px" v-if="categoryModal.meta_title">
                                                        <span>{{ categoryModal.meta_title ? categoryModal.meta_title.length <= 60 ? 60 - categoryModal.meta_title.length : 0 : 60 }}</span>
                                                        Remaining
                                                    </p>
                                                    <p class="userInfoTxt" v-if=" categoryModal.meta_title && categoryModal.meta_title.length <= 60 ">
                                                        Do not exceed 60 characters when entering the meta title for SEO
                                                    </p>
                                                    <p class="userInfoTxt" v-else-if=" categoryModal.meta_title && categoryModal.meta_title.length > 60 ">
                                                        Not SEO friendly
                                                    </p>
                                                </div>
                                            </div>
                                            <!-- meta title field ends here -->

                                            <!-- meta description field starts here -->
                                            <div class="col-md-12">
                                                <div class="formContentBox" style="position: relative">
                                                    <label for="category_meta_description">Meta Description</label>
                                                    <textarea
                                                        class="form-control foam-border comment-box mt-0"
                                                        rows="3"
                                                        cols="30"
                                                        name="comment"
                                                        placeholder="Meta description"
                                                        v-model="categoryModal.meta_description"
                                                    >
                                                    </textarea>
                                                    <p class="remainingTxt" style="top: 0px" v-if="categoryModal.meta_description">
                                                        <span>{{ categoryModal.meta_description ? categoryModal.meta_description.length <= 300 ? 300 - categoryModal.meta_description.length : 0 : 300 }}</span>
                                                        Remaining
                                                    </p>
                                                    <p class="userInfoTxt" v-if=" categoryModal.meta_description && categoryModal.meta_description.length <= 300">
                                                        Do not exceed 300 characters when entering the meta description for SEO
                                                    </p>
                                                    <p class="userInfoTxt" v-else-if=" categoryModal.meta_description && categoryModal.meta_description.length > 300">
                                                        Not SEO friendly
                                                    </p>
                                                </div>
                                            </div>
                                            <!-- meta description field ends here -->

                                            <!-- active & indexed field starts here -->
                                            <div class="col-md-12">
                                                <div class="updateProductBtnBox categorySideBar">
                                                    <div class="updateCtmCheckBox">
                                                        <p>Status</p>
                                                        <div class="productStatusBox">
                                                            <p>DISABLE</p>
                                                            <div class="actionSwitch">
                                                                <label class="switch">
                                                                    <input
                                                                        type="checkbox"
                                                                        id="category_status_field"
                                                                        v-model="categoryModal.isActive"
                                                                    />
                                                                    <span class="slider round"></span>
                                                                </label>
                                                            </div>
                                                            <p>ACTIVE</p>
                                                        </div>
                                                    </div>
                                                    <div class="updateCtmCheckBox">
                                                        <p>Indexed</p>
                                                        <div class="productStatusBox">
                                                            <p>NO</p>
                                                            <div class="actionSwitch">
                                                                <label class="switch">
                                                                    <input
                                                                        type="checkbox"
                                                                        id="category_indexed_field"
                                                                        :value="categoryModal.isIndexed"
                                                                        v-model="categoryModal.isIndexed"
                                                                    />
                                                                    <span class="slider round"></span>
                                                                </label>
                                                            </div>
                                                            <p>YES</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- active & indexed field ends here -->

                                            <!-- saving button starts here -->
                                            <div class="col-md-12 mt-3">
                                                <div class="assign-btn">
                                                    <loaderBtn class="product-btn" v-if="categoryModal.isLoading"/>
                                                    <button v-else type="submit" class="product-btn">
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                            <!-- saving button ends here -->

                                        </div>
                                    </form>
                                </ValidationObserver>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modalCloseIcon">
                    <button @click.prevent="categoryModal.isOpen = false">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                            <path
                                id="cross-small"
                                d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z"
                                transform="translate(-5.483 -5.483)"
                                fill="#fd4d5d"
                                stroke="#fd4d5d"
                                stroke-width="0.3"
                            />
                        </svg>
                    </button>
                </div>

            </div>
        </div>
    </transition>
    <!-- add category modal ends here -->

</template>

<script>
import Multiselect from "vue-multiselect";
import loaderBtn from "../loaderBtn.vue";
import { VueEditor } from "vue2-editor";
import {MessageBox, Message, Loading} from 'element-ui'

export default {
    name:"AddCategoryModal",
    data(){
        return {

            categoryModal:{
                name: "",
                slug: "",
                description: "",
                category_type: "main",
                parent: "",
                image: null,
                image_src: "",
                meta_title: "",
                meta_description: "",
                isActive: true,
                isIndexed: true,
                isOpen: false,
                isLoading: false,
                form: "",
            },
            categoriesLoader: false,

            categoryEditorOptions:{
                placeholder: 'Description',
                theme: 'snow',
                modules:{
                    toolbar:{
                        container:[ 
                            [{ "font": [] },],
                            [{'header': [1, 2, 3, 4, 5, 6, false]}],
                            ["blockquote", "code-block"],
                            ['bold', 'italic', 'underline', 'strike'],
                            ["clean"],
                            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                            [{ 'script': 'sub'}, { 'script': 'super' }],
                            [{'color': []}, {'background': []}],
                            [{ 'direction': 'rtl' }, { 'align': [] }],
                        ],
                    }
                }
            },

            imageValidations: {
                imgIsRequired: false,
                imgInvalidFormat: false,
                isNotImg: false,
            },

            parentCategories: [],
            parentsTimer: false,

        }
    },
    props:{
    },
    components:{
        Multiselect,
        VueEditor,
        loaderBtn,
    },
    computed:{
    },
    watch:{
    },
    methods:{

        openModal(form){

            this.categoryModal = {
                name: "",
                slug: "",
                description: "",
                category_type: "main",
                parent: "",
                image: null,
                image_src: "",
                meta_title: "",
                meta_description: "",
                isActive: true,
                isIndexed: true,
                isOpen: true,
                isLoading: false,
                form: form,
            }

            this.$nextTick((_) => {

                this.resetImageValidations();

                if(this.$refs.categoryFormObserver){

                    this.$refs.categoryFormObserver.reset();

                }

            });

        },

        slugGeneration(field){

            let slug = '';

            if(field == 'name'){

                slug = this.categoryModal.name.toLowerCase().trim();
                slug = slug.replace(/[^a-zA-Z0-9 ]/g, '');
                slug = slug.replace(/\s/g, '-');
                slug = slug.replace(/(\W)\W*/g, '$1');
                slug = slug.replace(/-$/, "");

            }else if(field == 'slug'){

                slug = this.categoryModal.slug.toLowerCase().trim();
                slug = slug.replace(/[^a-zA-Z0-9- ]/g, '');
                slug = slug.replace(/\s/g, '-');
                slug = slug.replace(/(\W)\W*/g, '$1');

            }

            this.categoryModal.slug = slug;

        },

        addImageHandle(e){

            if(e.target.files.length > 0){

                this.imageValidations = {
                    imgIsRequired:false,
                    imgInvalidFormat:false,
                    isNotImg:false,
                }

                const file = e.target.files[0];

                if(file.name.length > 50){

                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: "Uploaded file name must not exceed 50 characters.",
                    }); 

                    this.imageValidations.imgIsRequired = false

                    if(this.$refs.categoryImageInput){

                        this.$refs.categoryImageInput.value = '';

                    }

                    return

                }

                if(file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/webp' || file.type == 'image/png'){
                    
                    this.categoryModal.image = file;
                    this.categoryModal.image_src = URL.createObjectURL(file);
        
                    this.imageValidations.imgIsRequired = false;

                }else if(file.type.includes('image/')){

                    let uploadedFileType = file.type.slice(6);
          
                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: `${uploadedFileType.toUpperCase()} is not a valid file type.`,
                    });

                }else{

                    this.$notify({
                        type: "error",
                        title: "Alert",
                        message: "Uploaded file must be an image.",
                    }); 

                }

                if(this.$refs.categoryImageInput){

                    this.$refs.categoryImageInput.value = '';

                }

            }

        },
        resetImageValidations(){

            this.imageValidations = {
                imgIsRequired:false,
                imgInvalidFormat:false,
                isNotImg:false,
            }

        },
        attachmentOperations(operation){

            if(operation == 'preview'){

                this.$emit('image_preview',this.categoryModal.image_src);

            }else if(operation == 'remove'){

                MessageBox.confirm(
                    'Are you sure you want to remove the image?',
                    'Warning',
                    {
                        type: 'warning',
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'Cancel',
                    }
                ).then(() => {

                    this.categoryModal.image = null;
                    this.categoryModal.image_src = "";

                    this.$notify({
                        type: "success",
                        title: "Success",
                        message: "Image has been successfully removed.",
                    });

                }).catch(() => {})

            }

        },


        typeChangeHandle(){

            if(this.categoryModal.category_type == 'sub'){

                this.loadData(null); 

            }

        },
        customProductCategoryName({ name }){

            if(name.length > 15){

                let shortenedName = name.substr(0, 15);
                
                return `${shortenedName}...`;

            }else{

                return `${name}`;

            }

        },

        async loadProductCategories(query){

            if(query){

                clearTimeout(this.parentsTimer)

                this.parentsTimer = setTimeout(async () => {this.loadData(query)},500);

            }else{

                this.loadData(query)

            }

        },
        async loadData(keyword){
            
            this.categoriesLoader = true;
            try{
                let res = await this.$axios.get("getAllCategories",{
                    params:{
                        offset: 10,
                        search: keyword ? keyword : null
                    }
                });
                if(res.data.status_code == "1003"){

                    this.parentCategories = res.data.categories.data.filter((category) =>{

                        if(category.created_by_user){

                            return category;

                        }

                    });

                }
            }catch(error){

                this.$message({
                    type: 'error',
                    showClose: true,
                    message: error.response ? error.response.data.message : error.message,
                });

            }finally{

                this.categoriesLoader = false;

            }

        },

        async validate(){

            this.$refs.categoryFormObserver.validate().then((success) => {

                if(!success){

                    const errors = Object.entries(this.$refs.categoryFormObserver.errors)
                    .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

                    this.$refs.categoryFormObserver.refs[errors[0]["key"]].$el.scrollIntoView({ behavior: "smooth", block: "center",});

                    if(errors.length > 0){

                        if(!this.categoryModal.image){

                            this.imageValidations.imgIsRequired = true;

                        }

                    }else{

                        if(!this.categoryModal.image){

                            this.imageValidations.imgIsRequired = true;

                            document.querySelector("#category-featured-image").scrollIntoView({ behavior: 'smooth',block: "center" });

                        }

                    }

                }else{

                    if(!this.categoryModal.image){

                        this.imageValidations.imgIsRequired = true;

                        document.querySelector("#category-featured-image").scrollIntoView({ behavior: 'smooth',block: "center" });

                    }else{

                        this.handleAddCategory();

                    }

                }

            });

        },
        
        async handleAddCategory(){

            let formData = new FormData();

            formData.append("name", this.categoryModal.name);
            formData.append("slug", this.categoryModal.slug);
            formData.append("description", this.categoryModal.description);
            formData.append("type", this.categoryModal.category_type);
            formData.append("isActive", this.categoryModal.isActive ? 1 : 0);
            formData.append("isIndexed", this.categoryModal.isIndexed ? 1 : 0);

            if(this.categoryModal.category_type == "sub"){

                formData.append("parent_id", this.categoryModal.parent.id);

            }

            if(this.categoryModal.meta_title){

                formData.append("meta_title", this.categoryModal.meta_title);

            }

            if(this.categoryModal.meta_description){

                formData.append("meta_description", this.categoryModal.meta_description);

            }

            if(this.categoryModal.image){

                formData.append("image", this.categoryModal.image);

            }

            this.categoryModal.isLoading = true;
            try{
                let res = await this.$axios.post("/addCategory", formData);
                if(res.data.request_status == "successful"){

                    this.$notify({
                        type: "success",
                        title: "Success",
                        message: res.data.message,
                    });

                    this.$store.commit('settings_module/update_remaining_space',res.data.remaining_space.usage_space);

                    this.$store.commit('gallery_module/CLEAR_MODULE');

                    let record = {
                        category : res.data.category,
                        form: this.categoryModal.form
                    }

                    this.$emit('category_add_success',record);

                    this.categoryModal.isOpen = false;

                }
            }catch(error){

                if(error.response){

                    if(error.response.data.error.name){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.name[0],
                        });

                    }else if(error.response.data.error.slug){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.slug[0],
                        });

                    }else if(error.response.data.error.image){
                                
                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: "We encountered difficulties processing the featured image.",
                        });

                    }else if(error.response.data.error.includes('storage limit has been reached')){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error,
                        });

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }

                }else{

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    });

                }

            }finally{

                this.categoryModal.isLoading = false;

            }

        },

    },
}
</script>

<style scoped>

    .category-tab-img {
        /* height: 400px;
        width: 400px; */
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        overflow: hidden;
        position: relative;
    }
    .category-tab-img:hover {
        border-color: #409EFF;
    }
    .category-tab-img img{
        height: 100%;
        width: 100%;
    }
    .category-tab-img svg{
        position: absolute;
        width: 50px;
        top: 43%;
        left: 0;
        right: 0;
        margin: 0 auto;
        fill: #d9d9d9;
    }
    .category-tab-img:hover svg{
        fill: #409EFF;
    }



    /* Transition Effects */
    .modal-enter-active, 
    .modal-leave-active {
        transition: all 0.3s ease-in-out;
    }

    /* Initial state when entering */
    .modal-enter{
        opacity: 0;
        visibility: hidden;
    }

    /* Final state when leaving */
    .modal-leave-to {
        opacity: 0;
        visibility: hidden;
    }

</style>